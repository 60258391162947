import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import useLanguageStrings from '../hooks/useLanguageStrings';
import redirect from '../lib/redirect';

export default function Newsletter({ discipline }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const { sendResults } = useLanguageStrings();
  const [error, setError] = useState();
  const errorRef = useRef(error);
  errorRef.current = error;

  const form = useRef();
  const url = 'https://weareheadlight.activehosted.com/proc.php';

  const { pathname } = useLocation();
  const isEN = pathname.indexOf('/en') === 0;
  const redirectUrl = isEN ? '/en/thank-you' : 'tak';

  const _form_serialize = (form) => {
    if (!form || form.nodeName !== 'FORM') {
      return;
    }
    var i,
      j,
      q = [];
    for (i = 0; i < form.elements.length; i++) {
      if (form.elements[i].name === '') {
        continue;
      }
      switch (form.elements[i].nodeName) {
        case 'INPUT':
          switch (form.elements[i].type) {
            case 'text':
            case 'number':
            case 'email':
            case 'date':
            case 'time':
            case 'hidden':
            case 'password':
            case 'button':
            case 'reset':
            case 'submit':
              q.push(
                form.elements[i].name +
                  '=' +
                  encodeURIComponent(form.elements[i].value),
              );
              break;
            case 'checkbox':
            case 'radio':
              if (form.elements[i].checked) {
                q.push(
                  form.elements[i].name +
                    '=' +
                    encodeURIComponent(form.elements[i].value),
                );
              }
              break;
            case 'file':
              break;
          }
          break;
        case 'TEXTAREA':
          q.push(
            form.elements[i].name +
              '=' +
              encodeURIComponent(form.elements[i].value),
          );
          break;
        case 'SELECT':
          switch (form.elements[i].type) {
            case 'select-one':
              q.push(
                form.elements[i].name +
                  '=' +
                  encodeURIComponent(form.elements[i].value),
              );
              break;
            case 'select-multiple':
              for (j = 0; j < form.elements[i].options.length; j++) {
                if (form.elements[i].options[j].selected) {
                  q.push(
                    form.elements[i].name +
                      '=' +
                      encodeURIComponent(form.elements[i].options[j].value),
                  );
                }
              }
              break;
          }
          break;
        case 'BUTTON':
          switch (form.elements[i].type) {
            case 'reset':
            case 'submit':
            case 'button':
              q.push(
                form.elements[i].name +
                  '=' +
                  encodeURIComponent(form.elements[i].value),
              );
              break;
          }
          break;
      }
    }
    return q.join('&');
  };
  useEffect(() => {
    window.cfields = { 4: 'discipline', 5: 'sprog' };
    window._load_script = (url, callback) => {
      var head = document.querySelector('head'),
        script = document.createElement('script'),
        r = false;
      script.type = 'text/javascript';
      script.src = url;
      if (callback) {
        script.onload = script.onreadystatechange = function () {
          if (!r && (!this.readyState || this.readyState == 'complete')) {
            r = true;
            callback();
          }
        };
      }
      head.appendChild(script);
    };

    window._show_thank_you = function (id, message, trackcmp_url, email) {
      const vgoAlias =
        typeof visitorGlobalObjectAlias === 'undefined'
          ? 'vgo'
          : visitorGlobalObjectAlias;
      var visitorObject = window[vgoAlias];
      if (email && typeof visitorObject !== 'undefined') {
        visitorObject('setEmail', email);
        visitorObject('update');
      } else if (typeof trackcmp_url != 'undefined' && trackcmp_url) {
        // Site tracking URL to use after inline form submission.
        _load_script(trackcmp_url);
      }
      if (typeof window._form_callback !== 'undefined')
        window._form_callback(id);
      redirect(redirectUrl);
    };

    window._show_error = function (id, message, html) {
      setError(message);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const serialized = _form_serialize(form.current).replace(/%0A/g, '\\n');
    console.log(form.current);
    console.log(serialized);
    _load_script(
      'https://weareheadlight.activehosted.com/proc.php?' +
        serialized +
        '&jsonp=true',
    );
    return false;
  };

  return (
    <form
      action={url}
      method="post"
      target="_blank"
      onSubmit={handleSubmit}
      ref={form}
    >
      <input type="hidden" name="u" value="13" />
      <input type="hidden" name="f" value="13" />
      <input type="hidden" name="s" />
      <input type="hidden" name="c" value="0" />
      <input type="hidden" name="m" value="0" />
      <input type="hidden" name="act" value="sub" />
      <input type="hidden" name="v" value="2" />
      <input type="hidden" name="or" value="0d8a68ac99a0afe8088b3bfb75a4fbdf" />
      <input type="hidden" name="field[4]" value={discipline} />
      <input type="hidden" name="field[5]" value={isEN ? 'en' : 'da'} />
      <div className="newsletter__inputs">
        <input
          type="name"
          name="fullname"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          placeholder="Name"
          required
        />
        <input
          type="email"
          name="email"
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          placeholder="Email"
          required
        />
      </div>
      {errorRef.current && <p>{errorRef.current}</p>}
      <button type="submit" className="button uppercase">
        {sendResults}
      </button>
    </form>
  );
}

Newsletter.propTypes = {
  discipline: PropTypes.string.isRequired,
};
