import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import '../style/discipline.scss';
import Layout from '../layouts/plain';
import SEO from './seo';
import Rectangle from './rectangle';
import Newsletter from './newsletter';
import Title from './title';
import useLanguageStrings from '../hooks/useLanguageStrings';

const query = graphql`
  {
    disciplinesEN: allContentfulDiscipline(
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        name
        title
        description {
          description
        }
        phrase {
          phrase
        }
        strength {
          strength
        }
        weaknessIntro
        weakness {
          weakness
        }
        opportunityIntro
        opportunity {
          opportunity
        }
      }
    }
    disciplinesDA: allContentfulDiscipline(
      filter: { node_locale: { eq: "da" } }
    ) {
      nodes {
        name
        title
        description {
          description
        }
        phrase {
          phrase
        }
        strength {
          strength
        }
        weaknessIntro
        weakness {
          weakness
        }
        opportunityIntro
        opportunity {
          opportunity
        }
      }
    }
  }
`;

function theme(disciplineID) {
  switch (disciplineID) {
    default:
    case 'ambition':
      return 'theme-black';
    case 'exchange':
      return 'theme-blue';
    case 'integrity':
      return 'theme-white';
    case 'responsibility':
      return 'theme-yellow';
  }
}

function Discipline({ locale = 'en-US', id: disciplineID }) {
  const {
    disciplinesEN: { nodes: disciplinesEN },
    disciplinesDA: { nodes: disciplinesDA },
  } = useStaticQuery(query);

  const { bookAFreeSessionTitle, wantToKnowMore, yourDisciplineIs } =
    useLanguageStrings();

  const {
    title,
    description: { description },
    phrase: { phrase },
  } = useMemo(
    () =>
      /* eslint-disable */
      (locale === 'da' ? disciplinesDA : disciplinesEN).find(
        /* eslint-enable */
        ({ name }) => name === disciplineID,
      ),
    [disciplineID],
  );

  return (
    <>
      <SEO title={title} />
      <Layout id="discipline" theme={theme(disciplineID)}>
        <header>
          <Title />
        </header>
        <main>
          <div className="discipline__content">
            <div>
              <p>{yourDisciplineIs}</p>
              <h1 className="uppercase">{title}</h1>
              <p className="phrase">{phrase}</p>
              <p>{description}</p>
            </div>
            <div>
              <Rectangle />
              <h2>{bookAFreeSessionTitle}</h2>
              <p className="preform">{wantToKnowMore}</p>
              <Newsletter discipline={disciplineID} />
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
}

Discipline.defaultProps = {
  locale: null,
};

Discipline.propTypes = {
  id: PropTypes.string.isRequired,
  locale: PropTypes.string,
};

export default Discipline;
